
  import { defineComponent, onMounted, ref } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useRoute, useRouter } from 'vue-router';
  import { useAbility } from '@casl/vue';
  import Lightgallery from 'lightgallery/vue';
  import lgZoom from 'lightgallery/plugins/zoom';
  import lgVideo from 'lightgallery/plugins/video';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import * as Yup from 'yup';

  export default defineComponent({
    name: 'ads-overview',
    components: {
      Lightgallery,
      ErrorMessage,
      Field,
      Form,
    },
    data: () => ({
      plugins: [lgZoom, lgVideo],
    }),
    methods: {
      onInit: () => {
        console.log('lightGallery has been initialized');
      },
      onBeforeSlide: () => {
        console.log('calling before slide');
      },
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const route = useRoute();
      const router = useRouter();

      const { can } = useAbility();
      const ad = ref({});
      const selectedStatus = ref<number>();
      const submitButton = ref<HTMLButtonElement | null>(null);

      const translate = (text, options = {}) => {
        if (te(text)) {
          return t(text, options);
        } else {
          return text;
        }
      };

      const getAttachmentName = (item, index) => {
        return `${item.type.replace(/([A-Z])/g, ' $1')} - ${index + 1}`;
      };

      const downloadAttachment = (item) => {
        window.open(item.name, '_blank');
      };

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('adOverview'), [translate('ads')]);
      });
      const { data } = await store.dispatch(Actions.GET_AD, route.params.id);
      ad.value = data.data;
      console.log(ad.value);
      const goBack = () => {
        router.back();
      };

      const onSubmitUpdate = async (values) => {
        debugger;
        console.log(ad.value);
        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }

        const payload = {
          isApproved: values.isApproved,
          rejectionNote: values.rejectionNote,
        };
        await store.dispatch(Actions.UPDATE_AD_STATUS, {
          id: ad.value['id'],
          data: payload,
        });
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_UPDATED_ADS_REQUEST'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            router.push({
              name: 'ads-listing',
            });
          });
        }
      };

      const adsSchema = Yup.object().shape({
        isApproved: Yup.number()
          .required(() => translate('ACTIVE_STATUS_IS_REQUIRED_FIELD'))
          .label('Active Status')
          .nullable(),
        rejectionNote: Yup.string().when('isApproved', {
          is: 0,
          then: Yup.string()
            .required(() => translate('REJECTION_NOTE_IS_REQUIRED_FIELD'))
            .label('Rejection Note'),
        }),
      });

      const getBadgeClass = (val) => {
        let status = 'badge-light-success';
        if (val == 0) {
          status = 'badge-light-warning';
        } else if (val == 1) {
          status = 'badge-light-success';
        } else if (val == 2) {
          status = 'badge-light-primary';
        } else if (val == 3) {
          status = 'badge-light-danger';
        } else if (val == 4) {
          status = 'badge-light-info';
        }
        return status;
      };

      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');

      return {
        onSubmitUpdate,
        submitButton,
        selectedStatus,
        ad,
        translate,
        can,
        getBadgeClass,
        downloadAttachment,
        getAttachmentName,
        goBack,
        adsSchema,
      };
    },
  });
